<template>
  <div class="page-add">
    <a-form ref="formRef" :rules="rules" :model="formData" class="form-add">
      <a-form-item label="部门名称" name="department" class="form-item">
        <a-input
          :maxlength="32"
          placeholder="请输入部门名称"
          v-model:value="formData.department"
          class="form-item-width"
        />
      </a-form-item>
      <a-form-item label="上级部门" class="form-item">
        <a-cascader
          changeOnSelect
          v-model:value="formData.nextDepart"
          :options="realPermissionList"
          placeholder="请选择上级部门"
          showSearch
          :fieldNames="{ label: 'departmentName', value: 'id', children: 'departmentDTOList' }"
          class="form-item-width"
        />
      </a-form-item>
      <a-form-item label="" class="form-item">
        <section style="margin-left: 82px">
          <a-button class="cancel-btn" @click="handleCancel">取消</a-button>
          <a-button class="submit-btn" @click="comfirmAdd" :loading="loading">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRaw, toRefs, watch } from 'vue'
import '@/style/page-add.scss'
import { cmsNotice } from '@/utils/utils'
import { useRouter } from 'vue-router'
import { addDepartment, getDepartmentOrganizational } from '@/apis/businessManage'

export default defineComponent({
  setup(props, ctx) {
    const formRef = ref()
    const router = useRouter()
    const state = reactive({
      departmentList: [],
      loading: false
    })
    const validatorDepartment = (rule, value) => {
      if (value.trim() == '') {
        return Promise.reject('部门名称不能为空格')
      }
      return Promise.resolve()
    }
    const realPermissionList = computed(() => {
      // 处理可以选择的部门
      let newArray = state.departmentList.slice()
      function deleteDeepLevel(arr, num, disabled = false) {
        arr.forEach(item => {
          num--
          if (item.departmentDTOList) {
            num <= 0 ? deleteDeepLevel(item.departmentDTOList, num, true) : deleteDeepLevel(item.departmentDTOList, num)
          }

          item.disabled = disabled
          //   if (item.id === currentLevelId.value) {
          //     item.disabled = true
          //   }
          num++
        })
      }
      deleteDeepLevel(newArray, 2)
      return newArray
    })
    const dgOrganiz=(arr)=>{
      const fn=(list)=>{
        return list.map(item=>{
          if(item.departmentDTOList.length){
            fn(item.departmentDTOList)
          }else{
            delete item.departmentDTOList
          }
          return item
        })
      }
      return fn(arr)
    }
    const formData = reactive({
      department: undefined,
      nextDepart: undefined
    })
    const rules = {
      department: [
        {
          required: true,
          message: '请输入部门名称'
        },
        {
          validator: validatorDepartment
        }
      ]
    }
    const handleCancel = () => {
      formRef.value?.resetFields && formRef.value.resetFields()
      state.loading = false
      router.back()
    }
    const comfirmAdd = () => {
      state.loading = true
      formRef.value
        .validate()
        .then(() => {
          modalSubmit()
        })
        .catch(() => (state.loading = false))
    }
    const fetchDepartmentList = async () => {
      // 获取组织框架
      const res = await getDepartmentOrganizational()
      state.departmentList = dgOrganiz(res.data)
    }

    const modalSubmit = async () => {
      const res = await addDepartment({
        departmentName: formData.department,
        parentId: formData.nextDepart[formData.nextDepart.length - 1] || 0
      })
      if (!res.success) {
          state.loading = false
          return
      }
      cmsNotice('success', '添加成功')
      state.loading = false
      router.back()
     
    }
    onMounted(() => {
      fetchDepartmentList()
      formData.department = undefined
      formData.nextDepart = []
    })
    return {
      formData,
      ...toRefs(state),
      formRef,
      rules,
      comfirmAdd,
      realPermissionList,
      handleCancel
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-form-item-label) {
  width: 82px;
}
</style>
